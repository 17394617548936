import { FormikProps, isString } from 'formik';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import nameof from 'ts-nameof.macro';
import { nullIfNan } from '@helpers/Numbers';
import { sumBy } from 'lodash';

export function recalculateCargoDetails(formikProps: FormikProps<CreateCustomerRequestModel>) {
    if (!formikProps.values.hasCargoDimensions)
        return;

    const totalPlacesName = nameof.full<CreateCustomerRequestModel>(x => x.totalPlaces);
    formikProps.setFieldValue(totalPlacesName, nullIfNan(sumBy(formikProps.values.cargoDimensions, x => x.amount)));

    if (!formikProps.values.useTotalWeight || formikProps.values.hasCargoDimensions) {
        const totalWeight =
            nullIfNan(
                formikProps.values.cargoDimensions.reduce((sum, current) => {
                    const weightVal = isString(current.weight) ? (current.weight as string).replace(/,/, ".") : current.weight;
                    if (!current.isTotalWeight)
                        sum += ((+weightVal) * (+current.amount));
                    else
                        sum += (+weightVal)
                    return sum;
                }, 0),
            )?.toFixed(2) || '';
        formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalWeight), totalWeight);
    }

    const totalVolume =
        nullIfNan(
            formikProps.values.cargoDimensions.reduce((sum, current) => {
                const amount = +current.amount;
                const lengthVal = isString(current.length) ? (current.length as string).replace(/,/, ".") : current.length;
                const length = +lengthVal;
                const heightVal = isString(current.height) ? (current.height as string).replace(/,/, ".") : current.height;
                const height = +heightVal;
                const widthVal = isString(current.width) ? (current.width as string).replace(/,/, ".") : current.width;
                const width = +widthVal;
                return sum + length * height * width * 0.000001 * amount;
            }, 0),
        )?.toFixed(2) || '';

    formikProps.setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalVolume), totalVolume);
}
import { AirwaybillListItemDto } from '@models/awbs/awbsModels';
import { useState } from 'react';
import * as React from 'react';
import clsx from 'clsx';
import styles from './AirwaybillRow.module.scss';

interface Props {
	item: AirwaybillListItemDto;
	onClick: (id: string) => void;
}

enum FMAState {
	None,
	Success,
	Error
}

export const AirwaybillRow = ({item, onClick}: Props) => {
	const [ isExpanded, setIsExpanded ] = useState(false);
	const toggleExpand = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsExpanded(!isExpanded);
	}

	const getFMAState = (fmaCode: string) => {
		if (fmaCode == null) {
			return FMAState.None;
		}

		if (fmaCode.includes('ACK/FWB RECEIVED AND PROCESSED')) {
			return FMAState.Success;
		}

		if (fmaCode.includes('/ERR ')) {
			return FMAState.Error;
		}

		return FMAState.None;
	}

	const fmaMap = {
		[FMAState.None]: '',
		[FMAState.Success] : styles.isFMASuccess,
		[FMAState.Error]: styles.hasError
	};

	const renderRow = (item: AirwaybillListItemDto, className: string, isParent: boolean) => {
		const fmaState = getFMAState(item.FmaCode);

		return <tr key={item.Id}
				   onClick={() => onClick(item.Id)}
				   className={className}>
			{isParent ? <td onClick={(e) => toggleExpand(e)}>{item.ChildrenHouseAirwaybills.length > 0 &&
				<div className={clsx('icon-arrow_right', isExpanded ? styles.expanded : '')}/>}</td> : <td/>}
			<td className={fmaMap[fmaState]} title={item.FmaCode}/>
			<td>{item.AirwaybillNumber}</td>
			<td>{item.RoutingDepartureAirport?.CodeIata}</td>
			<td>{item.AirportOfDestination?.CodeIata}</td>
			<td>{item.CargoGrossWeight}</td>
			<td>{item.CargoChargeableWeight}</td>
			<td>{item.RequestedFlightAndDate}</td>
		</tr>;
	};

	return (
		<>
			{renderRow(item, clsx(isExpanded ? styles.expanded : styles.collapsed, item.IsFwbSent ? styles.isFwbSent : ''), true)}
			{isExpanded && item.ChildrenHouseAirwaybills.map((child, idx) =>
				renderRow(child, clsx(styles.houseAwb, idx === item.ChildrenHouseAirwaybills.length - 1 ? styles.last : '', child.IsFwbSent ? styles.isFwbSent : null), false))}
		</>);
}
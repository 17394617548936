import { BCCargoRouteDto } from '@models/customerApplications/customerApplicationModels';
import { TrackEventDto } from '@models/awbs/awbsModels';
import { SendingStatus } from '@root/enum';
import { BookingStatus } from '@models/enums';
import i18n from '@i18n';

export const getSendingStatuses = (collection: BCCargoRouteDto[]): SendingStatus[] => {
    return collection?.map(r => r.sendingStatusId);
}

export const getEventCodes = (collection: TrackEventDto[]): string[] => {
    return collection?.map(e => e.code);
}

export const hasEventCodes = (collection: string[], codes: string[]): boolean => {
    return collection?.filter(code => codes?.indexOf(code) >= 0).length > 0;
}

export const hasSendingStatuses = (collection: SendingStatus[], codes: SendingStatus[]): boolean => {
    return collection?.filter(code => codes?.indexOf(code) >= 0).length > 0;
}

export const isEqual = (viewStatus: BookingStatus, current: BookingStatus) => viewStatus === current;

export const getStatusTitle = (status: BookingStatus): string => {
    switch (status) {
        case BookingStatus.Request:
            return i18n.t('tracing.request');
        case BookingStatus.Booking:
            return i18n.t('tracing.booking');
        case BookingStatus.Execution:
            return i18n.t('tracing.execution');
        case BookingStatus.Closing:
            return i18n.t('tracing.closing');
    }
}
import React from 'react';
import { ReactComponent as IconInfo } from '@material-design-icons/svg/round/info.svg';
import s from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Tippy from '@tippyjs/react';
import { useStatuses } from '@scenes/booking/components/Status/useStatuses';
import { getStatusTitle } from '@helpers/statusHelper';

interface ITrackAlert {
    text: string;
    id?: string;
    status: 'success' | 'error';
    additionalInfo?: string;
}

const TrackAlert: React.FC<ITrackAlert> = ({ text, id, status, additionalInfo }) => {
    const { t } = useTranslation();
    const bookingStatus = useStatuses();

    return (
        <div className={cn(
            s.container,
            {
                [s.success]: status === 'success',
                [s.error]: status === 'error',
            })}>
            <div className={s.left}>
                {additionalInfo &&
                    <Tippy content={additionalInfo}>
                        <IconInfo fill='currentColor' />
                    </Tippy>
                }
                <span className={status === 'success' && s.awb}>{text}</span>
            </div>
            <span>
                <span className={s.statusPre}>{`${t('trackHistory.currentStatus')}: `}</span>
                <span className={s.statusText}>{getStatusTitle(bookingStatus.status)}</span>
            </span>
        </div>
    );
};

export default TrackAlert;
import { useEffect, useState } from 'react';
import { getEventCodes, getSendingStatuses, hasEventCodes, hasSendingStatuses } from '@helpers/statusHelper';
import { Codes, SendingStatus } from '@root/enum';
import { BookingStatus } from '@models/enums';
import { useAppSelector } from '@root/store';

type StatusState = {
    status: BookingStatus,
    delivered: boolean;
}

export function useStatuses(): StatusState {
    const [bookingStatus, setBookingStatus] = useState<BookingStatus>(BookingStatus.Request);
    const { traceData, traceLoading } = useAppSelector(s => s.tracking);
    const { bookingConfirmation } = useAppSelector(s => s.bookingConfirmation);
    const [isDelivered, setIsDelivered] = useState(false);

    useEffect(() => {
        if (traceData?.data && !traceLoading) {
            if (traceData?.isSuccess && traceData?.data?.traceInfo?.trackAndTrace?.events?.length > 0) {
                const codes = getEventCodes(traceData?.data?.traceInfo?.trackAndTrace?.events);
                if (hasEventCodes(codes, [Codes.BKD]))
                    setBookingStatus(BookingStatus.Booking);
                if (hasEventCodes(codes, [Codes.CLD, Codes.DEP, Codes.TRA, Codes.RCT, Codes.ARR, Codes.ARV, Codes.ARE]))
                    setBookingStatus(BookingStatus.Execution);
                if (hasEventCodes(codes, [Codes.DLV, Codes.RCV]) || traceData.data.traceInfo.eventsByLocations[traceData.data.traceInfo.trackAndTrace.destination]?.length > 0) {
                    setBookingStatus(BookingStatus.Closing);
                    setIsDelivered(codes.filter(code => code == Codes.DLV).length > 0);
                }
            }
        }
    }, [traceData?.data, bookingConfirmation]);

    useEffect(() => {
        if (bookingConfirmation && !traceData?.data) {
            const sendStatuses = getSendingStatuses(bookingConfirmation?.cargoRoutes);
            if (hasSendingStatuses(sendStatuses, [SendingStatus.BKD]))
                setBookingStatus(BookingStatus.Booking);
            if (hasSendingStatuses(sendStatuses, [SendingStatus.DEP]))
                setBookingStatus(BookingStatus.Execution);
            if (hasSendingStatuses(sendStatuses, [SendingStatus.DLV])) {
                setBookingStatus(BookingStatus.Closing);
                setIsDelivered(sendStatuses.filter(code => code == SendingStatus.DLV).length > 0);
            }
        }
    }, [bookingConfirmation]);

    return {
        status: bookingStatus,
        delivered: isDelivered,
    };
}
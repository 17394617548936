import React, { useState } from 'react';

import StandardNavigationSelect from '@components/select/StandardNavigationSelect';
import { OrganizationDictionaryItem } from '@models/organizations';
import OrganizationsService from '@services/OrganizationsService';
import { AxiosResponse } from 'axios';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';

import nameof from 'ts-nameof.macro';
import { useTranslation } from 'react-i18next';

type Props = {
    initialOrganizations?: OrganizationDictionaryItem[];
    onChangeOrganizations: (organizations?: OrganizationDictionaryItem[]) => void;
};

const OrganizationsSelect = (props: Props): JSX.Element => {
    const [organizations, setOrganizations] = useState<OrganizationDictionaryItem[]>(props.initialOrganizations ?? []);

    const onChangeOrganizations = (organizations?: OrganizationDictionaryItem[]): void => {
        setOrganizations(organizations);
        props.onChangeOrganizations(organizations);
    };

    const { t } = useTranslation();

    return (
        <StandardNavigationSelect<OrganizationDictionaryItem>
            onChange={(organizations): void => onChangeOrganizations(organizations)}
            optionLabel={(organization): JSX.Element => <span>{organization?.name}</span>}
            selectedLabel={(organization): JSX.Element => <span>{organization?.name}</span>}
            fetch={(nav): Promise<AxiosResponse<IPagingWrapper<OrganizationDictionaryItem>>> => new OrganizationsService().dictionary(nav)}
            filterPropertyName={nameof.full<OrganizationDictionaryItem>((x) => x.name)}
            getUniqueOptionValue={(x) => x.id}
            value={organizations}
            placeholder={t('admin.users.organizations')}
            isMulti
        />
    );
};

export default OrganizationsSelect;

import { InteractionType } from '@models/contacts/InteractionType';

export const interactionTypesOrderedArray = [
	InteractionType.Director,
	InteractionType.Administrator,
	InteractionType.Commercial,
	InteractionType.Operational,
	InteractionType.Payments,
];

export const interactionTypesAvailableForAddingArray = [
	InteractionType.Payments,
	InteractionType.Commercial,
	InteractionType.Operational
];

export const getRequiredInteractionTypesByContactIndex = (contactIndex: number) => {

	const startIndex = 2;

	const arr = [...interactionTypesOrderedArray];

	if (contactIndex < 2) {
		return [];
	}

	const el = arr[contactIndex - startIndex];

	return el != null ? [el] : [];
};
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { Navigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { FilterConnection } from '@models/entityNavigation/filtering';
import { ReactComponent as Copy } from '@assets/svg/legacy/copy.svg';
import { Collapse, Popover, PopoverBody } from 'reactstrap';
import debounce from 'awesome-debounce-promise';
import nameof from 'ts-nameof.macro';
import SortSelect, { createSortSelectValue } from '@components/select/SortSelect';
import { SortDirection } from '@models/entityNavigation/sorting';
import Paginator from '@components/paginator/Paginator';
import { GetCustomerRequestHistoryItemDto } from '@models/customerRequests/customerRequestHistoryModels';
import { useTranslation } from 'react-i18next';
import CustomerRequestsHistoryItem from '@scenes/customerRequestHistory/components/CustomerRequestsHistoryItem';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import listStyles from '@assets/list.module.scss';
import ColumnTitles from '@components/ColumnTitles';
import CustomerRequestsHistoryFilter from '@scenes/customerRequestHistory/components/CustomerRequestsHistoryFilter';
import { Loader } from '@components/index';
import i18n from 'i18next';
import { ItemsPerPage } from '@components/paginator/ItemsPerPage';
import { getRequestHistoryData } from '@store/customerRequests/customerRequestsHistoryStore';
import { updateSettingsAmount, updateSettingsPage } from '@store/localSettings';
import { Settings } from '@root/constants/localSettings';

const renderItems = (t: any, data: IPagingWrapper<GetCustomerRequestHistoryItemDto>) => {
    if (data?.items == null || data.items.length == 0) {
        return <div className={listStyles.nothingFound}>{t('customerRequestsHistory.nothingFound')}</div>;
    }

    return data.items.map(x => <CustomerRequestsHistoryItem key={x.id} data={x} />);
};

const CustomerRequestsHistoryPage = () => {
    const { t } = useTranslation();
    const availableSortingProperties = useMemo(
        () => [
            {
                label: t('customerRequestsHistory.date'),
                propertyName: nameof.full<GetCustomerRequestHistoryItemDto>((x) => x.createTime),
            },
        ],
        [i18n.language],
    );

    const [filter, setFilter] = useState<FilterConnection>(null);
    const { isFetching, pagingWrapper } = useAppSelector(x => x.customerRequestsHistory);
    const { requests } = useAppSelector(s => s.localSettings);
    const [pageNumber, setPageNumber] = useState(requests.page);
    const [pageSize, setPageSize] = useState(requests.amount);
    const [isPrintPopoverOpen, togglePrintPopover] = useState(false);
    const [isFilterOpen, toggleOpenFilter] = useState(true);
    const [sorting, setSorting] = useState(
        createSortSelectValue(availableSortingProperties[0], SortDirection.Descending),
    );
    const [nav, setNav] = useState<Navigation>({
        sorters: [{
            path: sorting?.propertyName,
            sortDirection: sorting?.sortDirection,
        }],
        filters: [],
        pagingFilter: {
            pageSize: requests.amount,
            pageNumber: requests.page,
        },
    });
    const dispatch = useAppDispatch();

    const fetch = (nav: Navigation) => {
        dispatch(getRequestHistoryData(nav));
    };

    const getDebounced = debounce(fetch, 600);

    useEffect(() => {
        getDebounced(nav);
    }, [nav]);

    useEffect(() => {
        setNav({
            filters: filter == null ? [] : [filter],
            sorters: nav.sorters,
            pagingFilter: {
                pageNumber: nav.pagingFilter.pageNumber,
                pageSize: nav.pagingFilter.pageSize,
            },
        });
    }, [filter, sorting]);

    useEffect(() => {
        setNav({
            filters: nav.filters,
            sorters: nav.sorters,
            pagingFilter: {
                pageNumber: pageNumber,
                pageSize: pageSize,
            },
        });

        getDebounced(nav);
    }, [pageNumber, pageSize]);

    const printPopoverId = `printPopoverId`;
    const tableWrapper = useRef();

    const onSetPageSize = (amount: number) => {
        setPageSize(amount);
        dispatch(updateSettingsAmount({ key: Settings.CustomerRequests, amount: amount }));
    };

    const onSetPageNumber = (num: number) => {
        setPageNumber(num);
        dispatch(updateSettingsPage({ key: Settings.CustomerRequests, page: num }));
    };

    return (
        <div>
            {/* Print popover. */}
            <Popover placement='bottom' target={printPopoverId} isOpen={isPrintPopoverOpen}>
                <PopoverBody>Feature is coming soon</PopoverBody>
            </Popover>

            <div>
                <span className={userItemsPageStyles.title__main}>{t('customerRequestsHistory.title')}</span>
                <span className={userItemsPageStyles.title__count}>
					{t('customerRequestsHistory.totalCount')} {pagingWrapper?.totalCount}
				</span>
            </div>

            <br />

            <div className={userItemsPageStyles.topPanel}>
                <div>
                    <button
                        style={{
                            display: pagingWrapper?.items && pagingWrapper.items.length > 0 ? 'inline-block' : 'none',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        id={printPopoverId}
                        onMouseEnter={(): void => togglePrintPopover(true)}
                        onMouseLeave={(): void => togglePrintPopover(false)}
                        type='button'
                    >
                        <Copy />
                    </button>
                </div>

                <div className={userItemsPageStyles.sortSelect}>
                    <SortSelect
                        availableProperties={availableSortingProperties}
                        onChange={setSorting}
                        value={sorting}
                    />
                </div>

                <a
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        toggleOpenFilter(!isFilterOpen);
                    }}
                    className={`${userItemsPageStyles.topPanel__filterBtn} ${
                        isFilterOpen ? userItemsPageStyles.topPanel__filterBtn__active : ''
                    }`}
                >
                    <span>{t('customerRequestsHistory.filter')}</span>
                    <div>
                        <i className='icon-filter' />
                    </div>
                </a>
            </div>

            <Collapse isOpen={isFilterOpen}>
                <CustomerRequestsHistoryFilter onChangeFilter={setFilter} />
            </Collapse>

            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <div className={listStyles.itemsTitles}>
                            <ColumnTitles
                                tableWrapper={tableWrapper}
                                i18tColumnNames={[
                                    'customerRequestsHistory.date',
                                    'number',
                                    'customerRequestsHistory.route',
                                    'customerRequestsHistory.cargoTypeName',
                                    'customerRequestsHistory.statusCode',
                                    'actions',
                                ]}
                            />
                        </div>
                        <div ref={tableWrapper}>{renderItems(t, pagingWrapper)}</div>
                    </div>
                    <div className={listStyles.paginator}>
                        <Paginator
                            totalResults={pagingWrapper?.totalCount}
                            limitPerPage={pageSize}
                            currentPage={pageNumber + 1}
                            onChangePage={(num) => onSetPageNumber(num)}
                            pageNeighbours={4}
                        />
                        <ItemsPerPage onChange={(amount) => onSetPageSize(amount)} value={pageSize} />
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomerRequestsHistoryPage;

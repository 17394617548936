import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { fetchContact, toggleContactActivation, updateContact } from '@store/contactsStore';
import { Loader } from '@components/index';
import PersonalInfoEditor, { EditorMode } from '@scenes/accountSettings/components/PersonalInfoEditor';
import { UpdateContact } from '@models/contacts';
import { unwrap } from '@helpers/reduxHelpers';
import { toast } from 'react-toastify';
import styles from './ContactEditPage.module.scss';
import SessionManager from '@root/SessionManager';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';
import AppModal from "@components/AppModal";

const ContactEditPage = (props: { contactId: string; onExit() }) => {
	const dispatch = useAppDispatch();

	const {t} = useTranslation();
	const [ deactivateReason, setDeactivateReason ] = useState('');
	const [ showReasonDialog, setShowReasonDialog ] = useState(false);
	const {t: tContact} = useTranslation('', {keyPrefix: 'contact'});

	const {contactId, onExit} = props;

	const {contact, isContactFetching, isContactEditFetching} = useAppSelector((x) => x.contacts);

	useEffect(() => {
		if (contactId == null) {
			return;
		}
		if (contactId == contact?.id) {
			return;
		}
		dispatch(fetchContact({id: contactId}));
	}, [ contactId ]);

	// const jobPositionName = useMemo(() => {
	// 	if (contact == null || contact.id == null || contact.jobPositionType == null) {
	// 		return null;
	// 	}

	// 	const jobPositionKey = jobPositionTypes.find((x) => x.value == contact.jobPositionType).key;

	// 	return t(`jobPositionTypes.${jobPositionKey}`);
	// }, [contact?.jobPositionType, jobPositionTypes, i18next.language]);

	const onSubmitEditPersonalInfo = async (model: UpdateContact) => {
		const result = await dispatch(updateContact(model));
		return unwrap(result);
	};

	const onClickToggleActivate = async () => {
		const isActivated = contact?.inactive == true;
		if (!isActivated && deactivateReason == '') {
			setShowReasonDialog(true);
			return;
		}

		const result = await dispatch(
			toggleContactActivation({
				id: contactId,
				isActivated,
				deactivateReason
			})
		);
		const response = unwrap(result);
		if (!response.isError) {
			toast.success(tContact(isActivated ? 'activated' : 'deactivated'));
			onExit();
		}
	};

	return (
		<>
			<Row>
				<Col>
					<Row>
						<Col>
							<div className={styles.topBar}>
								<a className={styles.backBtn} onClick={onExit}>
									<i className={`icon-chevron-left`}/>
									<span>{t('backToEmployees')}</span>
								</a>
							</div>
						</Col>
					</Row>

					<Row>
						<Col>
							<h4>
								{tContact('edit')}: {' '}
								<span className={styles.name}>
								{contact?.firstName} {contact?.lastName}
									{contact?.jobTitle != null ? `, ${contact.jobTitle}` : null}
							</span>{' '}
								{contact?.inactive == true ? (
									<span className={styles.deactivatedText}>{t('deactivated')}</span>
								) : null}
							</h4>
						</Col>
					</Row>

					<Row>
						<Col>
							{isContactFetching ? (
								<Loader/>
							) : (
								<PersonalInfoEditor
									data={contact}
									showCanLogin={true}
									mode={SessionManager.user.hasPermissions(ContactPermissionType.UpdateContact) ? EditorMode.Edit : EditorMode.View}
									onSubmit={onSubmitEditPersonalInfo}
									onSuccess={() => {
										toast.success(t('changesSaved'));
										onExit();
									}}
									onError={(msg) => toast.error(msg)}
								>
									{(renderEditor, onClickSubmit) => {
										return (
											<>
												{renderEditor()}
												{SessionManager.user.hasPermissions(
													ContactPermissionType.UpdateContact
												) && (
													<Row>
														<Col className={'text-center mb-3 mt-4'}>
															<button
																className={'btn btn-primary'}
																onClick={(e) => {
																	e.preventDefault();
																	onClickSubmit();
																}}
																disabled={isContactFetching || isContactEditFetching}
															>
																{t('save')}
															</button>
															<button
																className={'btn btn-primary ml-3'}
																onClick={(e) => {
																	e.preventDefault();
																	onClickToggleActivate();
																}}
																disabled={isContactFetching || isContactEditFetching}
															>
																{contact?.inactive == true
																	? t('activate')
																	: t('deactivate')}
															</button>
														</Col>
													</Row>
												)}
											</>
										);
									}}
								</PersonalInfoEditor>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
			{showReasonDialog && <AppModal isOpen={true} onClickCloseButton={() => setShowReasonDialog(false)} body={
				<div>
					<Row>
						<Col>
							<label>{t('deactivateLabel')}</label>
							<textarea
								className='form-control'
								onChange={e => setDeactivateReason(e.currentTarget.value)}
								value={deactivateReason}/>
						</Col>
					</Row>
				</div>
			} footer={
				<div className={'w-100'}>
					<button
						className={'btn btn-danger d-block float-left px-4'}
						onClick={(e) => {
							e.preventDefault();
							setShowReasonDialog(false);
						}}>{t('cancel')}</button>
					<button
						disabled={deactivateReason == ''}
						className={'btn btn-success d-block float-right px-5 btn-primary btn-green'}
						onClick={(e) => {
							e.preventDefault();
							setShowReasonDialog(false);
							onClickToggleActivate();
						}}>{t('deactivate')}</button>
				</div>
			}/>}
		</>
	);
};

export default ContactEditPage;
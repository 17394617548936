export enum InteractionType {
	Commercial = 852250000,
	Operational = 852250001,
	Payments = 852250002,
	Director = 557050000,
	Documents = 557050001,
	Administrator = 557050002
}

export type InteractionTypeDto = {
	key: string;
	value: number;
}
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AirwaybillsService from '@services/AirwaybillsService';
import {
    AirwaybillDto,
    CreateAirwaybill,
    FwbDto,
    UpdateAirwaybill,
    AirwaybillListItemDto,
    AwbListQuery, AirwaybillDetailsDto, FwbVersion
} from '@models/awbs/awbsModels';
import { UploadProgress } from '@models/shared';
import { createAppThunk } from '@helpers/reduxHelpers';
import { IPagingWrapper2 } from '@models/entityNavigation/IPagingWrapper';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { act } from "react-dom/test-utils";

export type State = {
    isFetching: boolean;
    fetchCount: number;
    airwaybill?: AirwaybillDto;
    airwaybills?: IPagingWrapper2<AirwaybillListItemDto>;
    fwbCode?: string;
    airwaybillDetails: AirwaybillDetailsDto;
};

export const uploadAndRecognizeAwbImage = createAppThunk(
    'airwaybills/uploadAndRecognizeAwbImage',
    async (arg: {
        parentId?: string;
        customerApplicationId: string;
        file: File;
        onUploadProgress: UploadProgress;
    }) => {
        const {data} = await new AirwaybillsService().recognizeImage(
            arg.customerApplicationId,
            arg.file,
            arg.onUploadProgress,
            arg.parentId
        );
        return data;
    }
);

export const createAwb = createAppThunk('airwaybills/createAwb', async (arg: { model: CreateAirwaybill }) => {
    const {data} = await new AirwaybillsService().create(arg.model);
    return data;
});

export const updateAwb = createAppThunk('airwaybills/updateAwb', async (arg: UpdateAirwaybill) => {
    const {data} = await new AirwaybillsService().update(arg);
    return data;
});

export const getAwbById = createAsyncThunk<AirwaybillDto, string, {
    state: State
}>('airwaybills/getAwbById', async (id: string, thunkAPI) => {
    const airwaybill = thunkAPI.getState().airwaybill;
    const airwaybillsService = new AirwaybillsService();

    if (id.startsWith("new-awb-")) {
        if (airwaybill.parentAirwaybillId != null) {
            const {data: parentAirwaybill} = await airwaybillsService.get(airwaybill.parentAirwaybillId);
            return {
                ...airwaybill,
                parentAirwaybillId: airwaybill.parentAirwaybillId,
                airwaybillNumber: parentAirwaybill.airwaybillNumber?.toUpperCase(),
                firstCarrierId: parentAirwaybill.firstCarrierId,
                commodityItem: '1',
                declaredValueForCarriage: 'NVD',
                declaredValueForCustoms: 'NVC',
                amountOfInsurance: 'XXX',
                isWeightOrValuationChargePrepaid: true,
                isOtherChargePrepaid: true,
            };
        }

        return airwaybill;
    }

    const {data} = await airwaybillsService.get(id);
    return data;
});

export const getNeighborOrChildrenAirwaybillsById = createAppThunk('airwaybills/getAirwaybillDetails',
    async (id: string) => {
        const {data} = await new AirwaybillsService().getAirwaybillDetailsById(id);
        return data;
    });

export const getAwbList = createAppThunk('airwaybills/list', async (nav: AwbListQuery) => {
    const {data} = await new AirwaybillsService().list(nav);
    return data;
});

export const removeAwbById = createAppThunk('airwaybills/removeAwbById', async (arg: string) => {
    await new AirwaybillsService().remove(arg);
});

export const sendFwb = createAppThunk('airwaybills/sendFwb', async (arg: { id: string, fwbCode: string }) => {
    await new AirwaybillsService().sendFwb(arg.id, arg.fwbCode);
});

export const changeFwbVersion = createAppThunk('airwaybills/changeFwbVersion', async (arg: {
    id: string,
    version: FwbVersion
}) => {
    const {data} = await new AirwaybillsService().changeFwbVersion(arg.id, arg.version);
    return data;
});

export const getFwbCode = createAppThunk('airwaybills/getFwbCode', async (arg: string) => {
    const {data} = await new AirwaybillsService().getFwbCode(arg);
    return data;
});

export const importFromFwb = createAppThunk('airwaybills/importFromFwb', async (arg: { model: FwbDto }) => {
    const {data} = await new AirwaybillsService().importFromFwb(arg.model);
    return data;
});

export const prepareNewAwb = createAction('airwaybills/prepareNewAwb', (model: AirwaybillDto) => {
    return {
        payload: model
    };
});

export const clearCurrentAirwaybill = createAction('airwaybills/clearCurrentAirwabill');

const slice = createSlice({
    name: 'airwaybills',
    initialState: {
        fetchCount: 0,
        isFetching: true
    } as State,
    reducers: {},
    extraReducers: (builder) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder
            .addCase(getAwbById.pending, (state, action) => {
                state.fetchCount ++;
                state.isFetching = state.fetchCount > 0;
                state.airwaybill = null;
                state.airwaybillDetails = null;
            })
            .addCase(getAwbById.rejected, (state, action) => {
                state.fetchCount --;
                state.isFetching = state.fetchCount > 0;
            })
            .addCase(getAwbById.fulfilled, (state, action) => {
                state.fetchCount --;
                state.isFetching = state.fetchCount > 0;
                state.airwaybill = action.payload;
            });

        builder
            .addCase(getNeighborOrChildrenAirwaybillsById.pending, (state, action) => {
                state.fetchCount ++;
                state.isFetching = state.fetchCount > 0;
                state.airwaybillDetails = null;
            })
            .addCase(getNeighborOrChildrenAirwaybillsById.rejected, (state, action) => {
                state.fetchCount --;
                state.isFetching = state.fetchCount > 0;
            })
            .addCase(getNeighborOrChildrenAirwaybillsById.fulfilled, (state, action) => {
                state.fetchCount --;
                state.isFetching = state.fetchCount > 0;
                state.airwaybillDetails = action.payload;
            });

        builder
            .addCase(getAwbList.pending, (state, action) => {
                state.fetchCount ++;
                state.isFetching = state.fetchCount > 0;
                state.airwaybills = null;
            })
            .addCase(getAwbList.rejected, (state, action) => {
                state.fetchCount --;
                state.isFetching = state.fetchCount > 0;
            })
            .addCase(getAwbList.fulfilled, (state, action) => {
                state.fetchCount --;
                state.isFetching = state.fetchCount > 0;
                state.airwaybills = action.payload;
            });

        builder.addCase(getFwbCode.fulfilled, (state, action) => {
            state.fwbCode = action.payload;
        });

        builder.addCase(clearCurrentAirwaybill,
            state => {
                state.airwaybill = null;
            }
        );

        builder.addCase(prepareNewAwb,
            (state, action) => {
                state.airwaybill = action.payload;
            }
        );

        builder
            .addCase(changeFwbVersion.pending,
                (state, action) => {
                    state.fwbCode = null;
                })
            .addCase(changeFwbVersion.fulfilled,
                (state, action) => {
                    state.fwbCode = action.payload;
                });
    },
});

export const {reducer} = slice;
export const {actions} = slice;

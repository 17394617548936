/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GetCustomerRequestDto } from '@models/customerRequests/customerRequestModels';

import MoonIcon from '@components/MoonIcon';
import { ReactComponent as Pencil } from '@assets/svg/legacy/pencil.svg';

import { localDateToUtcDate, toDDMMYYYYformat } from '@helpers/dateHelpers';

import styles from '../Search.module.scss';
import { splitCode } from '@helpers/specialHandlingCodesHelpers';
import clsx from "clsx";
import {Collapse} from "reactstrap";

const CustomerRequestInfo = (props: { customerRequest: GetCustomerRequestDto }): JSX.Element => {

    const { customerRequest: request } = props;

    const history = useHistory();

    const changeSearchDetails = (): void => {
        history.push(`/request/${request?.id}`);
    };

    const { t } = useTranslation();

	const [isCargoDimsShown, toggleCargoDimsShow] = useState(false);

    return (
        <>
            <div className={styles.header}>
                {t('requestSearchResults.searchDetails.header')}{' '}
                <button type="button" onClick={changeSearchDetails}>
                    <Pencil/>{' '}
                    {t('requestSearchResults.searchDetails.change')}
                </button>
            </div>
            {!!request && (
                <div className={styles.sidebarContainer}>
                    <div className={styles.sidebarRoute}>
                        {request.departureLocation?.codeIata}
                        <MoonIcon
                            icon="icon-arrow-right"
                            className="moon-1x ml-2 mr-2"
                        />
                        {request.destinationLocations?.map(x => x.codeIata).join(' , ')}
                    </div>
                    <div className={styles.paramsInfo}>
                        <div>
                            {t(
                                'requestSearchResults.searchDetails.seatsNumber',
                            )}
                        </div>
                        <div>{request.totalPlaces}</div>
                    </div>

                    <div className={styles.params}>
                        <div className={styles.paramsInfo}>
                            <div>
                                {t('requestSearchResults.searchDetails.weight')}
                            </div>
                            <div>
                                {request.totalWeight}{' '}
                                {t('units.kg')}
                            </div>
                        </div>
                        <div className={styles.paramsInfo}>
                            <div>
                                {t('requestSearchResults.searchDetails.volume')}
                            </div>
                            <div>
                                {request.totalVolume}{' '}
                                {t('units.m3')}
                            </div>
                        </div>
                    </div>

					{request.cargoDimensions != null &&
						request.cargoDimensions.length > 0 && <>

						<Collapse isOpen={isCargoDimsShown}>
							<div className={clsx(styles.paramsInfo, styles.cargoDimensionsWrapper)}>
								<div className={'pb-1'}>
									{t('requestSearchResults.searchDetails.cargoDimensions')}
								</div>
								<div>
									{request.cargoDimensions.map((x, i) => (
										<div key={i} className={styles.cargoDimensionBlock}>
											<span>{x.amount} {t('units.unit')}</span>
											<span>{x.length}x{x.width}x{x.height} {t('units.cm')}</span>
											<span>{x.weight} {t('units.kg')}</span>
										</div>
									))}
								</div>
							</div>
						</Collapse>

							<a className={styles.cargoDimensionsShowMore}
							   onClick={x => {
								   x.preventDefault();
								   toggleCargoDimsShow(!isCargoDimsShown);
							   }}>
								{t(!isCargoDimsShown ? 'requestSearchResults.searchDetails.showMore' : 'requestSearchResults.searchDetails.hide')}
								<span className={clsx(isCargoDimsShown ? 'rotate-180' : null)}>
									<i className={'icon-chevron-down'} />
								</span>
							</a>

						</>
					}

                    <div className={styles.paramsInfo}>
                        <div>
                            {t('request.natureOfCargo')}
                        </div>
                        <div>{request.natureOfCargoTitle}</div>
                    </div>
                    <div className={styles.paramsInfo}>
                        <div>
                            {t('shc.shc')}
                        </div>
                        <div>{request.specialHandlingCodes?.map(x => splitCode(x.name).code).join(', ')}</div>
                    </div>
                    {request.dateStartPlan && (
                        <div className={styles.paramsInfo}>
                            <div>
                                {t('requestSearchResults.plannedDepartDate')}
                            </div>
                            <div>
                                {toDDMMYYYYformat(
                                    localDateToUtcDate(
                                        new Date(request.dateStartPlan),
                                    ),
                                )}
                            </div>
                        </div>
                    )}
                    {request.number && (
                        <div className={styles.paramsInfo}>
                            <div>
                                {t('requestSearchResults.requestNumber')}
                            </div>
                            <div>{request.number}</div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CustomerRequestInfo;
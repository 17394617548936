import { AddressDatumDto } from '@models/awbs/awbsModels';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import AddressDataService from '@services/AddressDataService';
import { Button, Col, Row } from 'reactstrap';
import clsx from 'clsx';
import styles from './index.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { AddressDatumModal } from '@scenes/customerApplication/awb/components/AddressDatumModal';
import ValidationMessage from '@components/ValidationMessage';
import { useTranslation } from 'react-i18next';
import AppModal from '@components/AppModal';

interface Props {
    idFieldName: string;
    onChange?: (model: AddressDatumDto) => void;
    title: string;
    scannedDataFieldName?: string;
    lookupFieldName?: string;
    showAccountNumber?: boolean;
    allowEmptyAccountNumberTitle?: string;
    disabled?: boolean;
}

const RenderAddressRow = (props: {
    addressDatum: AddressDatumDto;
    isSelected: boolean;
    onSelectionChange: () =>void;
}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (props.isSelected  && props.addressDatum.id  && ref.current)  {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

    }, [props.isSelected]);

    return <tr
        key={props.addressDatum.id}
        className={props.isSelected ? styles.selected : null}
        ref={ref}
        onClick={() => props.onSelectionChange()}
    >
        <td>{props.addressDatum.name}</td>
        <td>{props.addressDatum.accountNumber}</td>
        <td>{props.addressDatum.scannedRawData}</td>
    </tr>;
};

export const AddressControl = (props: Props) => {
    const context = useFormikContext<AddressDatumDto>();
    const { errors } = context;
    const [field, _, helpers] = useField(props.idFieldName);
    const [scannedField] = useField(props.scannedDataFieldName);
    const [selectedAddress, setSelectedAddress] = useState<AddressDatumDto>(null);
    const [selectedInListAddress, setSelectedInListAddress] = useState<AddressDatumDto>(null);
    const [addressData, setAddressData] = useState<AddressDatumDto[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [scannedData, setScannedData] = useState('');
    const [addressAsString, setAddressAsString] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        let isCurrent = true;
        const fetchAddress = async (id: string): Promise<AddressDatumDto> => {
            let address = null;

            if (id != null && address?.id != id) {
                const data = await new AddressDataService().get(id);
                address = data.data;
            }

            return address;
        };

        setSelectedAddress(null);

        fetchAddress(field.value)
            .then(address => {
                if (isCurrent) {
                    setSelectedAddress(address);
                }
            });

        return () => {
            isCurrent = false;
        };
    }, [field.value]);

    useEffect(() => {
        const scannedValue = props.scannedDataFieldName && scannedField ? (scannedField.value || '') : '';
        if (scannedValue != scannedData)
            setScannedData(scannedValue);
    }, [scannedField.value, props.scannedDataFieldName]);

    useEffect(() => {
        if (selectedAddress == null) {
            return;
        }

        const fields: string[] = [];
        if (selectedAddress.isRequiredAccountNumber) {
            fields.push(selectedAddress.accountNumber);
        }
        fields.push(selectedAddress.name);

        const cityName = selectedAddress.city?.name || selectedAddress.cityName || '';
        if (cityName.length > 0) {
            fields.push(cityName);
        }

        fields.push(selectedAddress.stateProvince || '');
        fields.push(selectedAddress.postCode || '');
        fields.push(selectedAddress.address || '');
        fields.push(selectedAddress.contact || '');
        setAddressAsString(fields.filter(v => (v || '').length > 0).join(', '));

    }, [selectedAddress]);

    const searchAddressesDebounced = useDebouncedCallback((term: string, callback: (data: AddressDatumDto[]) => void) => {
        setSelectedInListAddress(null);
        const fetchAddresses = async () => {
            const { data } = await new AddressDataService().getItems(term);
            return data;
        };

        fetchAddresses()
            .then((data) => {
                callback(data);
            });
    });

    useEffect(() => {
        let isCurrent = true;
        searchAddressesDebounced(searchTerm, (data) => {
            if (isCurrent) {
                setAddressData(data);
            }
        });

        return () => {
            isCurrent = false;
        };
    }, [searchTerm]);

    const updateAddress = (addressDatum: AddressDatumDto) => {
        setSelectedAddress(addressDatum);
        helpers.setValue(addressDatum?.id);
        if (props.lookupFieldName) {
            context.setFieldValue(props.lookupFieldName, addressDatum)
                .then(() => {
                    context.validateForm();
                });
        }
    };
    return <div data-cy={props.idFieldName}>
        <h3>{props.title}</h3>
        <Row>
            <Col>
                <div className={styles.lookupField} data-cy={'address-select'}>
                    <input
                        className="form-control"
                        disabled
                        placeholder={props.title}
                        value={selectedAddress?.name || ''}
                    />
                    {!props.disabled &&
                        <Button data-cy={'search-button'} className={clsx(styles.button, styles.searchContact)}
                                onClick={() => {
                                    setSelectedInListAddress(selectedAddress);
                                    setIsSearchOpen(true);
                                }} />}
                </div>
                <ValidationMessage
                    name={field.name}
                    errors={errors}
                />
                {props.lookupFieldName && errors[props.lookupFieldName] &&
                    <div><span className="validationMessage">{t('validation.invalidAddress')}</span></div>}
            </Col>
        </Row>
        <Row>
            <Col>
                {(scannedData || addressAsString) &&
                    <div data-cy={'scanned-data'} className={clsx(styles.dummyTextarea, styles.height50)}>
                        {scannedData || addressAsString}
                    </div>}
            </Col>
        </Row>
        {isModalOpen &&
            <AddressDatumModal
                id={selectedInListAddress?.id || field.value}
                scannedData={selectedInListAddress != null ? '' : scannedData}
                showAccountNumber={props.showAccountNumber}
                allowEmptyAccountNumberTitle={props.allowEmptyAccountNumberTitle}
                title={props.title}
                onClose={(addressDatum) => {
                    setIsModalOpen(false);
                    if (addressDatum != null) {
                        searchAddressesDebounced(searchTerm, (data) => {
                            setAddressData(data);
                            setSelectedInListAddress(data.find(item => item.id === addressDatum.id));
                        });
                    }
                }}
            />}
        {isSearchOpen &&
            <AppModal
                size={'lg'}
                isOpen={true}
                onClickCloseButton={() => setIsSearchOpen(false)}
                body={<div className={styles.addressSearch}>
                    <input className="form-control"
                           type="text"
                           value={searchTerm}
                           onChange={(event) => setSearchTerm(event.target.value)} />
                    <div>
                        <Button
                            onClick={() => {
                                setSelectedInListAddress(null);
                                setIsModalOpen(true);
                            }}
                        >
                            {t('add')}
                        </Button>
                        <Button
                            disabled={selectedInListAddress == null}
                            onClick={() => setIsModalOpen(true)}
                        >
                            {t('awb.edit')}
                        </Button>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>Название</th>
                            <th>ИНН</th>
                            <th>Дополнительная информация</th>
                        </tr>
                        </thead>
                    </table>
                    <div className={styles.addressesTable}>
                        <table>
                            <tbody>
                            {addressData.map(item => <RenderAddressRow
                                key={item.id}
                                addressDatum={item}
                                isSelected={selectedInListAddress?.id == item.id}
                                onSelectionChange={() => setSelectedInListAddress(selectedInListAddress?.id == item.id ? null : item)}
                            />)}
                            </tbody>
                        </table>
                    </div>
                    <div className={clsx(styles.dummyTextarea, styles.searchScannedData)}>
                        {scannedData}
                    </div>
                </div>}
                footer={<div className={styles.addressSearchButtons}>
                    <Button
                        data-cy={'cancel-button'}
                        onClick={() => {
                            setSelectedInListAddress(null);
                            setIsSearchOpen(false);
                        }}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={selectedInListAddress == null}
                        onClick={() => {
                            updateAddress(selectedInListAddress);
                            setSelectedInListAddress(null);
                            setIsSearchOpen(false);
                        }}
                    >
                        {t('select')}
                    </Button>
                </div>} />}
    </div>;
};
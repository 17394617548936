import React from 'react';
import s from './style.module.scss';
import { useTranslation } from 'react-i18next';
import PropCard from '@components/PropCard';

const Stats = () => {
    const { t } = useTranslation();

    return (
        <div className={s.statsContainer}>
            <div className={s.block}>
                <div className={s.count}>200+</div>
                <div className={s.text}>{t('request.countries')}</div>
            </div>
            <div className={s.block}>
                <div className={s.count}>2450+</div>
                <div className={s.text}>{t('request.airports')}</div>
            </div>
            <div className={s.block}>
                <div className={s.count}>20+</div>
                <div className={s.text}>{t('request.airCarriers')}</div>
            </div>
        </div>
    );
};

export default Stats;

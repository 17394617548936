import { object, string } from 'yup';
import { ContactInfoDto } from '@models/organizations/accreditationModels';
import ContactsService from '@services/ContactsService';
import { t } from 'i18next';

const requiredMsg = t('validation.required');
const emailReqMsg = t('validation.email');
const atLeastOnePhoneRequired = t('validation.atLeastOnePhoneRequired');

export const createContactShape = () => {
	// Сохраняем старые значения email, чтобы избежать лишних проверок.
	const oldValidationResults: Record<string, { email: string, isValid: boolean }> = {};

	const validatePhoneNumbers = (model: ContactInfoDto): boolean => {
		return (model.phone || '') != '' || (model.mobilePhone || '') != '';
	}

    return object<ContactInfoDto>()
        .shape({
            email: string()
                .email(emailReqMsg)
                .required(requiredMsg)
                .test({
                    message: t('exceptions.AccountRegistrationException.UserAlreadyExists'),
                    name: 'email-already-exists',
                    test: async (value, context) => {
                        const id = context.parent.id;
                        const oldValue = oldValidationResults[id] || { email: '', isValid: true };

                        if (value == oldValue.email) {
                            return oldValue.isValid;
                        }

                        const data = await new ContactsService().checkEmail(value);
                        const existingId = data.data || '';
                        oldValue.email = value;
                        oldValue.isValid = id == null && existingId == '' || id != null && (existingId == '' || existingId == id);
                        oldValidationResults[id] = oldValue;

                        return oldValue.isValid;
                    }
                }),
            phone: string()
                .nullable()
                .test({
                    message: atLeastOnePhoneRequired,
                    name: 'phone-required',
                    test: (value, context) => {
                        return validatePhoneNumbers(context.parent);
                    }
                }),
            mobliePhone: string()
                .nullable()
                .test({
                    message: atLeastOnePhoneRequired,
                    name: 'mobile-phone-required',
                    test: (value, context) => {
                        return validatePhoneNumbers(context.parent);
                    }
                }),
            firstName: string()
                .required(requiredMsg),
            lastName: string()
                .required(requiredMsg),
            jobTitle: string()
                .nullable()
                .required(requiredMsg)
        });
}


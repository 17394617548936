import React from 'react';
import * as customerApplicationStore from '@store/customerApplications/customerApplicationsStore';
import { ApplicationState } from '@store/index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import s from './generalData.module.scss';
import DataRow from '@scenes/booking/components/GeneralData/components/DataRow';
import { Badge } from '@root/components';
import {ReactComponent as IconDimensions} from '@material-design-icons/svg/round/fit_screen.svg';
import {ReactComponent as IconWeight} from '@material-design-icons/svg/round/line_weight.svg';
import {ReactComponent as IconCount} from '@material-design-icons/svg/round/apps.svg';

const GeneralData: React.FC = () => {
  const { customerApplication, customerRequest } = useSelector<ApplicationState, customerApplicationStore.State>(
    (x) => x.customerApplications,
  );

  const { t } = useTranslation();

  return (
    <div className={s.container}>

      <DataRow name={t('customerApplication.form.cargoDetails')} value={customerApplication?.cargoDetails} />
      <DataRow name={t('customerApplication.form.stacked')} value={customerApplication?.isCargoStacked} />
      <DataRow name={t('customerApplication.form.turnover')} value={customerApplication?.isCargoTurnover} />
      <DataRow name={t('customerApplication.form.packingWithOtherGoods')}
               value={customerApplication?.allowPackingWithOtherGoods} />
      <DataRow name={t('customerApplication.form.carriageSpecialTerms')}>
        <Badge label={customerApplication?.specialConditions} color='blue' />
      </DataRow>
      <DataRow name={t('customerApplication.form.isCargoDangerous')} value={customerApplication?.isCargoDangerous} />

      {customerApplication?.isCargoDangerous &&
        <>
          <DataRow name={t('customerApplication.form.hazardClass')} value={customerApplication?.cargoDangerClass} />
          <DataRow name={t('customerApplication.form.cargoUnNumber')} value={customerApplication?.cargoUnNumber} />
          <DataRow name={t('customerApplication.form.cargoPackagedForPlaneType')}>
            <Badge label={customerApplication?.cargoPackagedForPlaneType == 0
              ? t('customerApplication.form.cargoPlane')
              : t('customerApplication.form.passengerPlane')} color='white' />
          </DataRow>
        </>
      }

      <DataRow name={t('customerApplication.form.senderAddress')} value={customerApplication?.senderAddress} />
      <DataRow name={t('customerApplication.form.senderCity')} value={customerApplication?.senderCity} />
      <DataRow name={t('customerApplication.form.senderStateProvince')}
               value={customerApplication?.senderStateProvince} />
      <DataRow name={t('customerApplication.form.recipientAddress')} value={customerApplication?.recipientAddress} />
      <DataRow name={t('customerApplication.form.recipientCity')} value={customerApplication?.recipientCity} />
      <DataRow name={t('customerApplication.form.recipientStateProvince')}
               value={customerApplication?.recipientStateProvince} />
      <DataRow name={t('customerApplication.form.refNumber')} value={customerApplication?.referenceNumber} />
      <DataRow name={t('customerApplication.form.awbProvidedBy')}>
        <Badge label={customerApplication?.awbProvidedBy == 0
          ? t('customerApplication.form.fromSender')
          : t('customerApplication.form.fromProvider')} color='white' />
      </DataRow>
      <DataRow name={t('customerApplication.form.email')} value={customerApplication?.email} />
      <DataRow name={t('customerApplication.form.firstName')} value={customerApplication?.firstName} />
      <DataRow name={t('customerApplication.form.lastName')} value={customerApplication?.lastName} />
      <DataRow name={t('customerApplication.form.phoneNumber')} value={customerApplication?.phoneNumber} />
      <DataRow name={t('customerApplication.form.additionalEmail')} value={customerApplication?.additionalEmail} />
      {customerRequest?.cargoDimensions && customerRequest?.cargoDimensions.length > 0 &&
        <DataRow name={t('requestSearchResults.searchDetails.cargoDimensions')}>
          {customerRequest?.cargoDimensions.map((d, i) =>
            <div key={i} className={s.dimension}>
              <span className={s.dimensionPart}>
                <IconCount />
                {`${d.amount} ${t('units.pc')}`}
              </span>
              <span className={s.dimensionPart}>
                <IconDimensions />
                {`${d.length}x${d.width}x${d.height} ${t('units.cm')}`}
              </span>
              {d?.weight > 0 &&
                <span className={s.dimensionPart}>
                  <IconWeight/>
                  ${d.weight} ${t('units.kg')}
                </span>
              }
            </div>,
          )}
        </DataRow>
      }
    </div>
  );
};

export default GeneralData;
import React, { useState, useMemo } from 'react';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import 'react-dates/lib/css/_datepicker.css';
import '@assets/reactCalendar.scss';
import { Col, CustomInput, Row } from 'reactstrap';
import SessionManager from '../../../SessionManager';
import { UserRoles } from '@config/ApiAuthorizationConstants';
import { OrganizationDictionaryItem } from '@models/organizations';
import OrganizationsService from '@services/OrganizationsService';
import LocationSelect from '@components/select/LocationSelect';
import LocationsService from '@services/LocationsService';
import { LocationDto, LocationType } from '@models/locations';
import { useTranslation } from 'react-i18next';
import { GroupOrFieldFilter } from '@components/Filter/groupOrFieldFilter';
import { FilterConnection, FilterOperator, FilterValue, FilterValueCondition } from '@models/entityNavigation/filtering';
import { Filter } from '@components/Filter';
import { SortSelectValue } from '@components/select/SortSelect';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import TypedSelect from '@components/inputs/TypedSelect';
import AirlinesService from '@services/AirlinesService';
import { AirlineDto } from '@models/customerRequests/customerRequestModels';
import DateRangeFilter from '@components/inputs/DateFilter';
import { GroupFilter } from '@components/Filter/groupFilter';
import { FieldFilter } from '@components/Filter/fieldFilter';
import { SortDirection } from '@models/entityNavigation/sorting';

export interface IDateFilterArgs {
	from?: string;
	to?: string;
}

export interface IPriceFilterArgs {
	from: number;
	to: number;
}

type Props = {
	pageSize?: number;
	pageNumber?: number;
	sorting?: SortSelectValue;
	onNavigation?: (navigation: StandardNavigation) => void;
};

const CustomerApplicationHistoryFilter: React.FC<Props> = (props) => {
	const [createdDateFilter, setCreatedDateFilter] = useState<GroupFilter[]>([]);
	const [organizations, setOrganizations] = useState<OrganizationDictionaryItem[]>([]);
	const [numberValue, setNumberValue] = useState<string | undefined>(undefined);
	const [selectedAirline, setSelectedAirline] = useState<AirlineDto>(null);
	const [showOwn, setShowOwn] = useState(false);
    const [locationsFrom, setLocationsFrom] = useState<LocationDto[]>([]);
    const [locationsTo, setLocationsTo] = useState<LocationDto[]>([]);

	const filter = useMemo(() => {
		const filterValues = [];

		if (selectedAirline != null) {
			filterValues.push(
				{
					type: 'field',
					name: 'airlineId',
					value: selectedAirline.id,
					condition: FilterValueCondition.Equals
				});
		}

		const getAwbNumberFilter = (): FieldFilter[] => {
			const awbTerm = (numberValue || '').trim();
			return awbTerm == null || awbTerm.trim().length == 0
				? []
				: [{
					type: 'field',
					condition: FilterValueCondition.Contains,
					value: awbTerm,
					name: 'airwaybillNumber'
				}];
		};

		const getOrganizationFilter = (): GroupFilter[] => {
			if (organizations == null || organizations.length == 0) {
				return [];
			}

			return [{
				type: 'group',
				operator: FilterOperator.Or,
				values: organizations.map(x => ({
					type: 'field',
					name: 'organizationId',
					condition: FilterValueCondition.Equals,
					value: x.id
				}))
			}]
		};

		const getShowOwnFilter = (): GroupFilter[] => {
			if (!showOwn) {
				return [];
			}

			return [{
				type: 'group',
				operator: FilterOperator.Or,
				values: [{
					type: 'field',
					name: 'creatorUserId',
					condition: FilterValueCondition.Contains,
					value: SessionManager.user.id
				}]
			}]
		}

		const getLocationsFilter = (locations: LocationDto[], airportFieldName: string): GroupFilter[] => {
            if (locations == null || locations.length == 0) {
                return [];
            }

            const fields: GroupOrFieldFilter[] = locations.map(value =>
                ({
                    type: 'field',
                    name: airportFieldName,
                    condition: FilterValueCondition.Equals,
                    value: value.id
                }));

            return [{
                type: 'group',
                operator: FilterOperator.Or,
                values: fields
            }];
        };

		const result: GroupOrFieldFilter = {
			type: 'group',
			operator: FilterOperator.And,
			values: [
				...filterValues,
				...createdDateFilter,
				...getAwbNumberFilter(),
				...getOrganizationFilter(),
				...getShowOwnFilter(),
				...getLocationsFilter(locationsFrom, 'departureAirportId'),
				...getLocationsFilter(locationsTo, 'destinationAirportId'),
			]
		};

		return result;
	}, [selectedAirline, createdDateFilter, numberValue, organizations, showOwn, locationsFrom, locationsTo]);

	const { t } = useTranslation();

	const minNumberDigitsRequired = 3;

	const clearFilters = () => {
		setSelectedAirline(null);
		setCreatedDateFilter([]);
		setNumberValue('');
		setOrganizations([]);
		setShowOwn(false);
		setLocationsFrom([]);
		setLocationsTo([]);
	};

	return <Filter
		filterFields={filter}
		pageNumber={props.pageNumber}
		pageSize={props.pageSize}
		onNavigation={props.onNavigation}
		sorting={props.sorting}
	>
		<div className={userItemsPageStyles.filter__block}>
			<div className={userItemsPageStyles.filter__top}>
				<span className={userItemsPageStyles.filter__title}>
					{t('customerApplicationHistory.filterOptions')}
				</span>
				<a
					href="#"
					className={userItemsPageStyles.filter__clear}
					onClick={(e) => {
						e.preventDefault();
						clearFilters();
					}}
				>
					{t('customerApplicationHistory.clearFilters')}
				</a>
			</div>

			<div className={userItemsPageStyles.filter__body}>
				<Row>
					<Col sm={6}>
						<div className={userItemsPageStyles.filter__select}>
							<span className={userItemsPageStyles.filter__item__title}>
								{t('airline')}
							</span>
							<TypedSelect
								initializeWithEmptyTerm
								isMulti={false}
								onChange={value => setSelectedAirline(value)}
								fetch={(term: string) => new AirlinesService().getUniqueAirlines(term)}
								isClearable={false}
								getOptionValue={option => option.id}
								getOptionLabel={option => <>{option.name}</>}
								selectedOptions={selectedAirline}
							/>
						</div>
					</Col>

					<Col sm={6}>
						<div className={userItemsPageStyles.filter__number}>
							<span className={userItemsPageStyles.filter__item__title}>
								{t('customerApplicationHistory.number')}
							</span>
							<input
								className="form-control"
								value={numberValue || ''}
								onChange={(x) => setNumberValue(x.target.value || '')}
								placeholder={t('customerApplicationHistory.enterNumber')}
							/>
							{numberValue && numberValue.length < minNumberDigitsRequired && (
								<span className="text-danger">
									{t('customerApplicationHistory.minNumberDigitsRequired', { value: minNumberDigitsRequired })}
								</span>
							)}
						</div>
					</Col>
				</Row>

				<Row>
					<Col sm={6}>
						<DateRangeFilter
							title={t('customerApplicationHistory.selectDateRange')}
							fieldName='createTime'
							filter={createdDateFilter}
							onFilterChanged={setCreatedDateFilter}
						/>
					</Col>

					<Col sm={6}>
						{SessionManager.isAuthenticated() &&
							SessionManager.user.hasAnyRole(UserRoles.superuser, UserRoles.tenantAdmin) ? (
							<div className={userItemsPageStyles.filter__organizations}>
								<span className={userItemsPageStyles.filter__item__title}>
									{t('customerApplicationHistory.organizations')}
								</span>
								<TypedSelect<OrganizationDictionaryItem, true, true>
									isMulti
									isPartialLoading
									fetchPartial={(term, pageNumber) => {
										const filter: FilterConnection[] = [];
										term = (term || '').trim();

										const service = new OrganizationsService();
										if (term != '') {
											const filterCn = new FilterConnection(FilterOperator.And);
											const termFilterValue = new FilterValue('name', FilterValueCondition.Contains, term);
											filterCn.values.push(termFilterValue);
											filter.push(filterCn);
										}

										return service.dictionary({
											filters: filter,
											pagingFilter: {
												pageNumber: pageNumber,
												pageSize: 10
											},
											sorters: [{ path: 'name', sortDirection: SortDirection.Ascending }]
										})
									}}
									getOptionLabel={o => <span>{o.name}</span>}
									getOptionValue={o => o.id}
									isClearable={false}
									onChange={setOrganizations}
									selectedOptions={organizations}
									placeholder={t('customerApplicationHistory.searchOrganizations')}
									initializeWithEmptyTerm
								/>
							</div>
						) : (
							<div className={userItemsPageStyles.filter__switch}>
								<span className={userItemsPageStyles.filter__item__title}>
									{t('viewMode.title')}
								</span>
								<CustomInput
									onChange={() => setShowOwn(!showOwn)}
									className="custom-switch-md"
									type="switch"
									id="exampleCustomSwitch"
									name="customSwitch"
									label={t('viewMode.own')}
									checked={showOwn}
								/>
							</div>
						)}
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<div>
							<span className={userItemsPageStyles.filter__item__title}>
								{t('customerRequestsHistory.source')}
							</span>
							<LocationSelect
								isClearable={true}
								isMulti={true}
								fetch={(term) => new LocationsService().getKnownLocations(term, LocationType.Airport, true)}
								onChange={setLocationsFrom}
								loadingMessage={t('options.loadingOptions')}
								noOptionsMessage={t('options.noOptions')}
								placeholder={t('customerApplicationHistory.airportNameOrIata')}
								selectedOptions={locationsFrom}
								initializeWithEmptyTerm
							/>
						</div>
					</Col>
					<Col md={6}>
						<div>
							<span className={userItemsPageStyles.filter__item__title}>
								{t('customerRequestsHistory.target')}
							</span>
							<LocationSelect
								isClearable={true}
								isMulti={true}
								fetch={(term) =>
									new LocationsService().getKnownLocations(term, LocationType.Airport, true)
								}
								onChange={setLocationsTo}
								loadingMessage={t('options.loadingOptions')}
								noOptionsMessage={t('options.noOptions')}
								placeholder={t('customerApplicationHistory.airportNameOrIata')}
								selectedOptions={locationsTo}
								initializeWithEmptyTerm={true}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	</Filter>
};

export default CustomerApplicationHistoryFilter;
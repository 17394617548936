import { createSlice } from '@reduxjs/toolkit';
import { UploadProgress } from '@models/shared';
import { createAppThunk } from '@helpers/reduxHelpers';
import AirlinesService from '@services/AirlinesService';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { AirlineDictionaryItemDto } from '@models/airlines';

export type State = {
	airlines?: IPagingWrapper<AirlineDictionaryItemDto>;
	isFetching: boolean;
};

export const uploadAirlineLogo = createAppThunk(
	'airlines/uploadLogo',
	async (arg: { airlineId; file: File; onUploadProgress: UploadProgress }) => {
		const { data } = await new AirlinesService().uploadLogo(arg.airlineId, arg.file, arg.onUploadProgress);
		return data;
	}
);

export const getAirlines = createAppThunk('airlines/getAirlineById', async (nav: StandardNavigation) => {
	const { data } = await new AirlinesService().get(nav);
	return data;
});

export const toggleAirlineCalcAvailability = createAppThunk(
	'airlines/toggleCalcAvailability',
	async (arg: { airlineId: string; excluded: boolean }) => {
		const { data } = await new AirlinesService().toggleCalcAvailability(arg.airlineId, arg.excluded);
		return data;
	}
);

export const changeFwbVersion = createAppThunk(
    'airlines/changeFwbVersion',
    async (arg: {airlineId: string; fwbVersion: number}) => {
        const {data} = await new AirlinesService().changeFwbVersion(arg.airlineId, arg.fwbVersion);
    }
);

const slice = createSlice({
	name: 'airlines',
	initialState: {
		airlines: null,
		isFetching: true,
	} as State,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAirlines.fulfilled, (state, action) => {
			state.isFetching = false;
			state.airlines = action.payload;
		});

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(getAirlines.pending, (state, action) => {
			state.isFetching = true;
		});

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(getAirlines.rejected, (state, action) => {
			state.isFetching = false;
		});

		builder.addCase(uploadAirlineLogo.fulfilled, (state, action) => {
			if (state?.airlines?.items?.length > 0) {
				const airline = state?.airlines?.items.find((x) => x.id === action.meta.arg.airlineId);
				airline.logoId = action.payload;
			}
		});

		builder.addCase(toggleAirlineCalcAvailability.fulfilled, (state, action) => {
			if (state?.airlines?.items?.length > 0) {
				const airline = state?.airlines?.items.find((x) => x.id === action.meta.arg.airlineId);
				airline.calcExcluded = action.meta.arg.excluded;
			}
		});

		builder.addCase(changeFwbVersion.fulfilled, (state, action) => {
			if (state?.airlines?.items?.length > 0) {
				const airline = state?.airlines?.items.find((x) => x.id === action.meta.arg.airlineId);
				airline.defaultFwbVersion = action.meta.arg.fwbVersion;
			}
		});
	},
});

export const { reducer } = slice;
export const { actions } = slice;

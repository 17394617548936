import React, { ChangeEvent, SyntheticEvent, useRef } from 'react';
import cn from 'classnames';
import { randomString } from '@helpers/stringHelpers';
import styles from './textArea.module.scss';
import s from '../Input/input.module.scss';

interface ITextArea {
	className?: string;
	initValue?: string;
	placeholder?: string;
	name?: string;
	disabled?: boolean;
	value?: string;
	onChange?(value: ITextArea['value']): void;
	minRow?: number;
	maxRow?: number;
	row?: number;
	required?: boolean;
	onBlur?(e: SyntheticEvent): void;
}

const TextArea: React.FC<ITextArea> = (props) => {
	const { initValue, className, value, row, onChange, required, placeholder, ...anyProps } = props;
	const id = useRef<string>(randomString(6));

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value);

	return (
		<div className={styles.container}>
			{required && !value && <span className={s.required} />}
			<textarea
				rows={row || 3}
				className={cn([styles.textarea, className])}
				id={id.current}
				defaultValue={initValue}
				value={value || ''}
				onChange={onChange ? handleChange : undefined}
				placeholder={placeholder}
				{...anyProps}
			/>
		</div>
	);
};

export default TextArea;

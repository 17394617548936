import {HttpError, UploadProgress} from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {StandardNavigation} from '@models/entityNavigation/StandardNavigation';
import {IPagingWrapper} from '@models/entityNavigation/IPagingWrapper';
import {AirlineDictionaryItemDto} from '@models/airlines';
import { AirlineDto } from '@models/customerRequests/customerRequestModels';
import {
	FilterConnection,
	FilterOperator,
	FilterValue,
	FilterValueCondition
} from "@models/entityNavigation/filtering";
import nameof from "ts-nameof.macro";

export default class AirlinesService {

    public uploadLogo = (
        airlineId: string,
        file: File,
        onUploadProgress: UploadProgress
    ) => {

        const formData = new FormData();
        formData.append("file", file);

        return AuthorizedHttpClient.put<string & HttpError>(
            `api/airlines/${airlineId}/logo`,
            formData,
            {
                onUploadProgress
            }
        );
    }

    public get = (nav: StandardNavigation) => {
        return AuthorizedHttpClient.post<IPagingWrapper<AirlineDictionaryItemDto> & HttpError>(
            `api/airlines/dictionary`,
            nav
        );
    }

	public findByCodeIata = (codeIata: string) => {
		const navState = new StandardNavigation();
		const fcnOr = new FilterConnection(FilterOperator.Or);
		fcnOr.values.push(new FilterValue(
			nameof.full<AirlineDictionaryItemDto>((x) => x.codeIata),
			FilterValueCondition.Contains,
			codeIata
		));
		navState.filters.push(fcnOr);

		return this.get(navState);
	}

	public getAllAirlines = () => {
		return AuthorizedHttpClient.get<AirlineDto[] & HttpError>('api/Airlines/get-all-airlines');
	}

    public getUniqueAirlines = (term?: string) => {
        return AuthorizedHttpClient.get<AirlineDto[] & HttpError>(`api/Airlines/get-unique-airlines/${term}`);
    }

    public getAwbAirlines = () => {
        return AuthorizedHttpClient.get<AirlineDto[] & HttpError>('api/Airlines/get-awb-airlines');
    }

    public toggleCalcAvailability = (
        airlineId: string,
        excluded: boolean) => {
        return AuthorizedHttpClient.post<HttpError>(
            `api/airlines/toggleCalcAvailability`,
            {
                airlineId: airlineId,
                excluded: excluded
            }
        );
    }

    public changeFwbVersion = (
        airlineId: string,
        fwbVersion: number) => {
        return AuthorizedHttpClient.post<HttpError>(
            `api/airlines/changeFwbVersion`,
            {
                airlineId: airlineId,
                fwbVersion: fwbVersion
            }
        );
    }

}
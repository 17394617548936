import {
	LoginToAccount,
	RefreshTokenDto,
	RegisterModel,
	RegistrationResultDto,
	SuccessLoginDto,
} from '@models/accounts/accountModels';
import { HttpError } from '@models/shared';
import { ChangePasswordModel, ChangePasswordResponse } from '@models/accounts/changePasswordModels';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { AxiosResponse } from 'axios';
import HttpClient from '@core/HttpClient';
import { PreferencesDto } from '@models/accounts/preferencesModels';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { UserDictionaryItem } from '@models/users';

export default class AccountService {
	public async login(model: LoginToAccount): Promise<AxiosResponse<SuccessLoginDto | HttpError>> {
		return await HttpClient.post<SuccessLoginDto | HttpError>(`api/account/login`, model);
	}

	public async refreshToken(
		token: string,
		refreshToken: string
	): Promise<AxiosResponse<RefreshTokenDto & HttpError>> {
		return await HttpClient.post<RefreshTokenDto & HttpError>(`api/Account/RefreshToken`, {
			token: token,
			refreshToken: refreshToken,
		});
	}

	public async getMyTokenByExternalAuth(requestId: string): Promise<AxiosResponse<SuccessLoginDto | HttpError>> {
		return await HttpClient.get<SuccessLoginDto | HttpError>(
			`api/Account/GetMyTokenByExternalAuth?requestId=${requestId}`
		);
	}

	public async register(data: RegisterModel): Promise<AxiosResponse<RegistrationResultDto>> {
		return await HttpClient.post<RegistrationResultDto>('api/Account/Register', data);
	}

	public async changePassword(data: ChangePasswordModel): Promise<AxiosResponse<ChangePasswordResponse>> {
		return await AuthorizedHttpClient.post<ChangePasswordResponse>('api/account/ChangePassword', data);
	}

	public async createPasswordRequest(data: { email: string }): Promise<AxiosResponse<void>> {
		return await HttpClient.post('api/Account/ResetPasswordRequest', data);
	}

	// public async getProfile(): Promise<AxiosResponse<ProfileDto>> {
	// 	return await AuthorizedHttpClient.get<ProfileDto>('api/Account/Profile');
	// }

	public async changePreferredLanguage(languageId: string): Promise<AxiosResponse<void>> {
		return await AuthorizedHttpClient.put('api/Account/preferredLanguage', {
			languageId,
		});
	}

	public fetchPreferences = () => {
		return AuthorizedHttpClient.get<PreferencesDto>('api/account/preferences');
	};

	public async logout(): Promise<AxiosResponse<void>> {
		return await AuthorizedHttpClient.get(`api/Account/LogOut`);
	}

	public fetchDictionary = async (navigation: StandardNavigation) => {
		return await AuthorizedHttpClient.post<IPagingWrapper<UserDictionaryItem>>(`api/account/dictionary`, navigation);
	};
}
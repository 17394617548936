import { BookingStatus } from '@models/enums';
import i18n from '@i18n';
import { getStatusTitle } from '@helpers/statusHelper';

type StatusModel = {
    status: BookingStatus,
    title: string,
    alternativeTitle?: string
}

export const statuses: StatusModel[] = [
    {
        status: BookingStatus.Request,
        title: getStatusTitle(BookingStatus.Request),
    },
    {
        status: BookingStatus.Booking,
        title: getStatusTitle(BookingStatus.Booking),
    },
    {
        status: BookingStatus.Execution,
        title: getStatusTitle(BookingStatus.Execution),
    },
    {
        status: BookingStatus.Closing,
        title: getStatusTitle(BookingStatus.Closing),
        alternativeTitle: i18n.t('tracing.arrived'),
    },
];
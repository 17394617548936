import { FormikProps, isString } from 'formik';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import nameof from 'ts-nameof.macro';
import { nullIfNan } from '@helpers/Numbers';
import { sumBy } from 'lodash';
import * as XLSX from 'xlsx';

import { CargoDimensionDto } from '@models/customerRequests/customerRequestModels';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export function recalculateCargoDetails(formikProps: FormikProps<CreateCustomerRequestModel>, forceWeightPerPiece: boolean = false) {

    const {setFieldValue, values} = formikProps;

    if (!values.hasCargoDimensions) {
        return;
    }

    // calculate total number of Places from Dimensions
    const totalPlaces = nullIfNan(sumBy(values.cargoDimensions, x => x.amount));
    setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalPlaces), totalPlaces);

    // calculate total weight from dimensions
    if (!values.useTotalWeight || forceWeightPerPiece) {
        const totalWeight =
            nullIfNan(
                values.cargoDimensions.reduce((sum, current) => {
                    const amount = +current.amount;
                    const weightVal = isString(current.weight) ? (current.weight as string).replace(/,/, ".") : current.weight;
                    const weight = +weightVal;
                    return sum + weight * amount;
                }, 0),
            )?.toFixed(2) || '';
        setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalWeight), totalWeight);
    }

    // calculate Volume from dimensions
    const totalVolume =
        nullIfNan(
            values.cargoDimensions.reduce((sum, current) => {
                const amount = +current.amount;
                const lengthVal = isString(current.length) ? (current.length as string).replace(/,/, ".") : current.length;
                const length = +lengthVal;
                const heightVal = isString(current.height) ? (current.height as string).replace(/,/, ".") : current.height;
                const height = +heightVal;
                const widthVal = isString(current.width) ? (current.width as string).replace(/,/, ".") : current.width;
                const width = +widthVal;

                const coef = 0.000001; //cm3 to m3

                return sum + length * height * width * coef * amount;
            }, 0),
        )?.toFixed(2) || '';

    setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.totalVolume), totalVolume);
}

export function parseCargoDimensionsFromXlsx(
    workbook: XLSX.WorkBook,
    formikProps: FormikProps<CreateCustomerRequestModel>,
): void {
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const {setFieldValue} = formikProps;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileData: any[] = XLSX.utils.sheet_to_json(worksheet, {
        header: ['Amount','Length','Width','Height','Weight'],
        range: 3,
        blankrows: false
    });

    if (fileData && fileData.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cargoDimensions: CargoDimensionDto[] = fileData.map((data: any) => {
            return {
				amount: nullIfNan(parseInt(data['Amount'])),
                length: nullIfNan((data['Length'])),
                width: nullIfNan(parseFloat(data['Width'])),
                height: nullIfNan(parseFloat(data['Height'])),
                weight: nullIfNan(parseFloat(data['Weight'])),
                isTotalWeight: parseInt(data['WeightPerPlace']) === 0
            };
        });

        setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions), cargoDimensions);
        setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.hasCargoDimensions), true);
        setFieldValue(nameof.full<CreateCustomerRequestModel>(x => x.useTotalWeight), false);
    }
}

export function onClickParseXlsx(e: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<CreateCustomerRequestModel>
): void {
    const files = e.target.files || [],
        f = files[0];
    const reader = new FileReader();
    reader.onload = (fileReader): void => {
        if (fileReader.target !== null) {
            const data: Uint8Array = new Uint8Array(
                fileReader.target.result as ArrayBuffer,
            );
            const workbook = XLSX.read(data, { type: 'array' });
            parseCargoDimensionsFromXlsx(workbook, formikProps);
        }
    };
    reader.readAsArrayBuffer(f);
    e.target.value = null;
}
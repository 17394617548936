import React, { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@root/store';
import { getTariffsDictionary } from '@store/tariffsStore';

import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { TariffDictionaryItem } from '@models/tariffs';
import { Col, Row } from 'reactstrap';
import dictionaryStyles from '../../components/DictionaryStyles.module.scss';
import clsx from 'clsx';
import {
    FilterConnection,
    FilterOperator,
    FilterValue,
    FilterValueCondition,
} from '@models/entityNavigation/filtering';
import debounce from 'awesome-debounce-promise';
import nameof from 'ts-nameof.macro';
import { PropertySorter } from '@models/entityNavigation/sorting';
import Paginator from '@components/paginator/Paginator';
import { Loader } from '@components/index';

import TariffItem from '@scenes/admin/dictionaries/tariffs/components/TariffItem';

import { useTranslation } from 'react-i18next';
import PageHeader from '@components/PageHeader';

const TarrifsPage = (): JSX.Element => {
    const { isFetching, tariffs } = useAppSelector((x) => x.tariffs);
    const [term, setTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(0);

    const dispatch = useAppDispatch();

    const getDebounced = debounce(
        (navigation: StandardNavigation) => {
            dispatch(getTariffsDictionary(navigation));
        },
        800
    );

    const onSearchByTerm = (newTerm: string): void => {
        newTerm = (newTerm || '').trim();
        setTerm(newTerm);

        if (newTerm != term) {
            setPageNumber(0);
        }
    };

    const onChangePage = (pageNumber: number): void => {
        setPageNumber(pageNumber || 0);
    };

    const filter = useMemo(() => {
        const nav = new StandardNavigation();
        nav.pagingFilter.pageSize = 10;
        nav.pagingFilter.pageNumber = pageNumber;

        // Create sorters.
        const sorter = new PropertySorter();
        nav.sorters.push(sorter);

        if (term != '') {
            const fcnOr = new FilterConnection(FilterOperator.Or);

            const codeFilterValue = new FilterValue(
                nameof.full<TariffDictionaryItem>((x) => x.code),
                FilterValueCondition.Contains,
                term
            );

            fcnOr.values.push(codeFilterValue);

            nav.filters.push(fcnOr);
        }

        return nav;
    }, [term, pageNumber]);

    useEffect(() => {
        getDebounced(filter);
    }, [filter]);

    const { t } = useTranslation();

    return (
        <>
            <PageHeader title={t('admin.dictionaries.tariffs')} backUrl="/admin/dictionaries" />
            <Row className="mb-3">
                <Col md={12}>
                    <input
                        type="text"
                        className={clsx('form-control', dictionaryStyles.termFilter)}
                        placeholder={t('admin.tariffs.search')}
                        onKeyUp={(e): void =>
                            onSearchByTerm(e.currentTarget.value)
                        }
                    />
                </Col>
            </Row>

            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <Row>
                        <Col>
                            <table
                                className={clsx(
                                    'table table-hover table-responsive-sm',
                                    dictionaryStyles.table
                                )}
                            >
                                <thead>
                                    <tr>
                                        <th>{t('admin.tariffs.code')}</th>
                                        <th>{t('admin.tariffs.type')}</th>
                                        <th>{t('admin.tariffs.departureAirportCode')}</th>
                                        <th>{t('admin.tariffs.destinationAirportCode')}</th>
                                        <th>{t('admin.tariffs.airlineCode')}</th>
                                        <th>{t('admin.tariffs.currencyCode')}</th>
                                        <th>{t('admin.tariffs.product')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tariffs?.items?.map((tariff, index) => (
                                        <TariffItem
                                            key={index}
                                            tariff={tariff}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Paginator
                                totalResults={tariffs?.totalCount}
                                limitPerPage={10}
                                currentPage={pageNumber + 1}
                                onChangePage={(p): void => onChangePage(p)}
                                pageNeighbours={4}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default TarrifsPage;

import React, { useEffect, useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import { GroupFilter } from '@components/Filter/groupFilter';
import { FieldFilter } from '@components/Filter/fieldFilter';
import { FilterValueCondition, FilterOperator } from '@models/entityNavigation/filtering';

export interface IDateRange {
    from?: string;
    to?: string;
}

interface DateFilterProps {
    title: string;
    fieldName: string;
    filter?: GroupFilter[];
    onFilterChanged?: (filter: GroupFilter[]) => void;
}

const DateRangeFilter: React.FC<DateFilterProps> = ({
    title,
    filter,
    fieldName,
    onFilterChanged
}) => {
    const [dateRange, setDateRange] = useState<IDateRange>({});
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(null);
    
    const { t } = useTranslation();

    useEffect(() => {
        if (filter == null || filter.length == 0) {
            setDateRange({});
            return;
        }

        if (filter[0].type == 'group') {
            let from = '';
            let to = '';
            if (filter[0].values.length > 0) {
                if (filter[0].values[0].type == 'field')
                from = filter[0].values[0].value as string;
            }
            if (filter[0].values.length > 1) {
                if (filter[0].values[1].type == 'field')
                to = filter[0].values[1].value as string;
            }

            setDateRange({from, to});
        }
    }, [filter]);

    const getDateFilter = (dateFilter: IDateRange): GroupFilter[] => {
        const endDate = dateFilter.to || '';

        const getRangeEndFilter = (): FieldFilter[] => {
            return endDate == '' ? [] : [{
                type: 'field',
                name: fieldName,
                condition: FilterValueCondition.LessOrEqualsThan,
                value: endDate
            }];
        };

        const startDate = dateFilter.from || '';

        return endDate == '' && startDate == ''
            ? []
            : [
                {
                    type: 'group',
                    operator: FilterOperator.And,
                    values: [
                        {
                            type: 'field',
                            name: fieldName,
                            condition: FilterValueCondition.MoreOrEqualsThan,
                            value: startDate
                        },
                        ...getRangeEndFilter()
                    ]
                }
            ];
    };



    return (
        <div className={userItemsPageStyles.filter__calendar}>
            <span className={userItemsPageStyles.filter__item__title}>
                {title}
            </span>
            <DateRangePicker
                focusedInput={focusedInput}
                isOutsideRange={() => null}
                startDatePlaceholderText={t('customerRequestsHistory.startDate')}
                endDatePlaceholderText={t('customerRequestsHistory.endDate')}
                startDate={
                    dateRange?.from
                        ? moment(dateRange.from).startOf('day')
                        : null
                }
                endDate={
                    dateRange?.to ? moment(dateRange.to).endOf('day') : null
                }
                startDateId={`${fieldName}_start_date_id`}
                endDateId={`${fieldName}_end_date_id`}
                displayFormat={() => 'DD/MM/YYYY'}
                customArrowIcon={<i className={`calendarArrowIcon icon-arrow-long`} />}
                inputIconPosition="before"
                showDefaultInputIcon={true}
                customInputIcon={<i className={`calendarInputIcon icon-calendar`} />}
                onDatesChange={({ startDate, endDate }) => {
                    const from = startDate?.startOf('day').toISOString();
                    const to = endDate?.endOf('day').toISOString();
                    const dateRange = { from, to };
                    setDateRange(dateRange);
                    onFilterChanged(getDateFilter(dateRange));
                }}
                onFocusChange={(inputName) => {
                    setFocusedInput(inputName);
                }}
            />
        </div>
    );
};

export default DateRangeFilter;
import React, { useEffect, useMemo, useState } from 'react';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import 'react-dates/lib/css/_datepicker.css';
import '@assets/reactCalendar.scss';
import LocationSelect from '@components/select/LocationSelect';
import { Col, Row } from 'reactstrap';
import { LocationDto, LocationType } from '@models/locations';
import LocationsService from '@services/LocationsService';
import { Filter } from '@components/Filter';
import { GroupFilter } from '@components/Filter/groupFilter';
import { FilterConnection, FilterOperator, FilterValueCondition } from '@models/entityNavigation/filtering';
import { FieldFilter } from '@components/Filter/fieldFilter';
import { GroupOrFieldFilter } from '@components/Filter/groupOrFieldFilter';
import { useTranslation } from 'react-i18next';
import debounce from 'awesome-debounce-promise';
import styles from './CustomerRequestHistoryFilter.module.scss';
import clsx from 'clsx';
import DateRangeFilter from '@components/inputs/DateFilter';

type Props = {
    onChangeFilter: (filter: FilterConnection) => void;
};

const debounceNumberChange = debounce((updater: () => void) => updater(), 500, {});

const CustomerRequestsHistoryFilter: React.FC<Props> = ({ onChangeFilter }) => {
    const [locationsFrom, setLocationsFrom] = useState<LocationDto[]>([]);
    const [locationsTo, setLocationsTo] = useState<LocationDto[]>([]);
    const [createdDateFilter, setCreatedDateFilter] = useState<GroupFilter[]>([]);
    const [departureDateFilter, setDepartureDateFilter] = useState<GroupFilter[]>([]);
    const [requestNumber, setRequestNumber] = useState('');
    const [term, setTerm] = useState('');

    const { t } = useTranslation();

    const filter = useMemo(() => {
        const getLocationsFilter = (locations: LocationDto[], cityFieldName: string, airportFieldName: string, checkDestinationAirportGroups?: boolean): GroupFilter[] => {
            if (locations == null || locations.length == 0) {
                return [];
            }

            const fields: GroupOrFieldFilter[] = locations.map(value =>
                ({
                    type: 'field',
                    name: value.type == LocationType.City ? cityFieldName : airportFieldName,
                    condition: FilterValueCondition.Equals,
                    value: value.id
                }));

            if (checkDestinationAirportGroups) {
                fields.push(
                    {
                        type: 'group',
                        operator: FilterOperator.OneOf,
                        values: [
                            {
                                type: 'field',
                                name: 'destinationAirportGroup.AirportGroupToAirports',
                                condition: FilterValueCondition.Any,
                                value: null
                            },
                            {
                                type: 'field',
                                name: 'destinationAirportGroup.AirportGroupToAirports.AirportId',
                                condition: FilterValueCondition.Any,
                                value: locations.map(value => value.id).join(',')
                            }
                        ]
                    });
            }

            return [{
                type: 'group',
                operator: FilterOperator.Or,
                values: fields
            }];
        };

        const getNumberFilter = (): FieldFilter[] => {
            return requestNumber == null || requestNumber.trim().length == 0
                ? []
                : [{
                    type: 'field',
                    condition: FilterValueCondition.Contains,
                    value: requestNumber,
                    name: 'number'
                }];
        };

        const result: GroupOrFieldFilter = {
            type: 'group',
            operator: FilterOperator.And,
            values: [
                ...(getLocationsFilter(locationsFrom, 'departureCityId', 'departureAirportId')),
                ...(getLocationsFilter(locationsTo, 'destinationCityId', 'destinationAirportId', true)),
                ...createdDateFilter,
                ...departureDateFilter,
                ...getNumberFilter()
            ]
        };

        return result;
    }, [locationsFrom, locationsTo, createdDateFilter, departureDateFilter, requestNumber]);

    useEffect(() => {
        debounceNumberChange(() => setRequestNumber(term));
    }, [term]);
    const minNumberDigitsRequired = 3;

    return (
        <Filter filterFields={filter} onFilter={onChangeFilter}>
            <div className={userItemsPageStyles.filter__block}>
                <div className={userItemsPageStyles.filter__top}>
                    <span className={userItemsPageStyles.filter__title}>
                        {t('customerRequestsHistory.filterOptions')}
                    </span>
                    <a
                        href="#"
                        className={userItemsPageStyles.filter__clear}
                        onClick={e => {
                            e.preventDefault();
                            setLocationsFrom([]);
                            setLocationsTo([]);
                            setCreatedDateFilter([]);
                            setDepartureDateFilter([]);
                            setRequestNumber(null);
                        }}
                    >
                        {t('customerRequestsHistory.clearFilters')}
                    </a>
                </div>
                <div className={userItemsPageStyles.filter__body}>
                    <Row>
                        <Col sm={6}>
                            <div className={userItemsPageStyles.filter__location}>
                                <span className={userItemsPageStyles.filter__item__title}>
                                    {t('customerRequestsHistory.source')}
                                </span>
                                <LocationSelect
                                    isClearable={true}
                                    isMulti={true}
                                    fetch={(term) => new LocationsService().getKnownLocations(term)}
                                    onChange={setLocationsFrom}
                                    loadingMessage={t('options.loadingOptions')}
                                    noOptionsMessage={t('options.noOptions')}
                                    placeholder={t('customerRequestsHistory.airportOrCity')}
                                    selectedOptions={locationsFrom}
                                    initializeWithEmptyTerm={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className={userItemsPageStyles.filter__location}>
                                <span className={userItemsPageStyles.filter__item__title}>
                                    {t('customerRequestsHistory.target')}
                                </span>
                                <LocationSelect
                                    isClearable={true}
                                    isMulti={true}
                                    fetch={(term) => new LocationsService().getKnownLocations(term)}
                                    onChange={setLocationsTo}
                                    loadingMessage={t('options.loadingOptions')}
                                    noOptionsMessage={t('options.noOptions')}
                                    placeholder={t('customerRequestsHistory.airportOrCity')}
                                    selectedOptions={locationsTo}
                                    initializeWithEmptyTerm={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.dateNumberFilter}>
                        <DateRangeFilter
                            title={t('customerRequestsHistory.selectDateRange')}
                            fieldName='createTime'
                            filter={createdDateFilter}
                            onFilterChanged={setCreatedDateFilter}
                        />
                        <DateRangeFilter
                            title={t('customerRequestsHistory.selectDepartureDateRange')}
                            fieldName='dateStartPlan'
                            filter={departureDateFilter}
                            onFilterChanged={setDepartureDateFilter}
                        />
                        <div className={userItemsPageStyles.filter__number}>
                            <span className={userItemsPageStyles.filter__item__title}>
                                {t('customerApplicationHistory.requestNo')}
                            </span>
                            <input
                                className={clsx('form-control', styles.numberFilter)}
                                value={term || ''}
                                onChange={(x) => {
                                    const value = x.target.value;
                                    setTerm(value);
                                }}
                                placeholder={t('customerApplicationHistory.enterNumber')}
                            />
                            {term && term.length < minNumberDigitsRequired &&
                                <span className="text-danger">
                                    {t('customerApplicationHistory.minNumberDigitsRequired')
                                        .replace('{value}', minNumberDigitsRequired + '')}
                                </span>}
                        </div>
                    </Row>
                </div>
            </div>
        </Filter>
    );
};

export default CustomerRequestsHistoryFilter;
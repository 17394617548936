import React, { PropsWithChildren, SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import s from "./link.module.scss";

export interface IButton {
  href: string;
  download?: string;
  isActive?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
  className?: string;
  title?: string;
  disabled?: boolean;
  isLight?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const Link: React.FC<PropsWithChildren<IButton>> = ({
  children,
  download,
  href,
  isActive,
  icon,
  className,
  iconPosition,
  title,
  disabled,
  isLight,
  target,
}) => {
  const navigate = useHistory();

  const navigateTo = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate.push(href);
  };

  return (
    <a
      target={target}
      {...(download && { download: download, rel: "noreferrer", target: "_blank" })}
      {...(href && href.includes("http") && { target: "_blank" })}
      title={title}
      className={cn([
        s.link,
        s.trim,
        {
          [s.linkActive]: isActive || disabled,
          [s.linkDisable]: disabled,
          [s.linkWhite]: isLight,
          [s.reverse]: iconPosition === "end",
        },
        className,
      ])}
      href={href || "#"}
      onClick={download || href.includes("http") ? undefined : navigateTo}
    >
      {icon && <span className={s.icon}>{icon}</span>}
      {children}
    </a>
  );
};

export default Link;
